<template>
  <div id="dataCenter">
    <van-sticky :offset-top="0">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
          />数据中心
        </van-col>
      </van-row>
      <van-row class="tabs">
        <van-col
          span="6"
          v-for="(item, index) in tabs"
          :key="index"
          :class="[item.type == type ? 'tab-item-active' : 'tab-item']"
          @click="onTabClick(item)"
          >{{ item.text }}</van-col
        >
      </van-row>
    </div>
    </van-sticky>
    <van-row v-if="type == '1'" class="chart-container">
      <van-col span="24" class="total-bar">
        <van-row>
          <van-col span="8" class="bar-title">旅行社总数</van-col>
          <van-col span="16" class="bar-value"
            >{{ companies_total_count }}({{
              companies_pending_review_count
            }})</van-col
          >
        </van-row>
      </van-col>

      <van-col span="24" class="chart-item">
        <van-row>
          <van-col span="24" class="chart-item">
            <div class="chart-title">旅行社评分</div>
            <van-row class="chart-wrapper" id="10">
              <van-col span="24">
                <canvas
                  v-if="type == '1'"
                  id="companies_rate_star"
                  style="width: 90vw; height: 30vh"
                ></canvas>
              </van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24" class="chart-item">
        <div class="chart-title">旅行社规模</div>
        <!-- <transition name="van-slide-left"> -->
        <van-row
          class="progress-bar"
          v-for="(item, index) in companies_group_data"
          :key="index"
        >
          <van-col span="4">
            <svg-icon :data_iconName="item.icon" :className="'ground_icon'" />
          </van-col>
          <van-col span="20" style="padding-left: 20px">
            <div class="bar-top">
              <span class="bar-text">{{ item.name }}</span>
              <span class="bar-number">{{ item.percent }}</span>
              <span class="bar-unit">个</span>
            </div>
            <div class="bar-bottom">
              <div
                class="bar"
                :style="{ backgroundColor: item.bg_color }"
              ></div>
              <div
                class="bar-progress"
                :style="{
                  backgroundColor: item.color,
                  width: (item.percent / companies_total_count) * 100 + '%',
                }"
              ></div>
            </div>
          </van-col>
        </van-row>
        <!-- </transition> -->
      </van-col>
    </van-row>
    <van-row v-if="type == '2'" class="chart-container">
      <van-col span="24" class="total-bar">
        <van-row>
          <van-col span="8" class="bar-title">导游总数</van-col>
          <van-col span="16" class="bar-value"
            >{{ guides_total_count }}({{
              guides_pending_review_count
            }})</van-col
          >
        </van-row>
      </van-col>
      <van-col span="24" class="chart-item">
        <div class="chart-title">导游性别比例</div>
        <van-row class="chart-wrapper">
          <van-col span="8">
            <van-row v-for="(item, index) in gender_data" :key="index">
              <van-col span="24" class="text-center">
                <span
                  class="circle-1"
                  :style="{ backgroundColor: colors[index] }"
                ></span>
                <span class="circle-text">{{ item.name }}</span>
              </van-col>
              <van-col span="24" class="text-right">
                <div class="number-container">
                  {{ item.percent }}<span class="unit">人</span>
                </div>
              </van-col>
            </van-row>
          </van-col>
          <van-col span="16">
            <div id="30">
              <canvas
                id="gender"
                v-if="type == '2'"
                style="width: 80vw; height: auto"
              ></canvas>
            </div>
          </van-col>
        </van-row>
      </van-col>
      <van-col span="24" class="chart-item">
        <div class="chart-title">导游评分</div>
        <van-row class="chart-wrapper" id="20">
          <van-col span="24">
            <canvas
              v-if="type == '2'"
              id="rate_star"
              style="width: 90vw; height: 30vh"
            ></canvas>
          </van-col>
        </van-row>
        <!-- <div class="chart-wrapper" id="20">
          <canvas  v-if="type == '2'" id="rate_star" style="width: 90vw; height: 30vh"></canvas>
        </div> -->
      </van-col>

      <van-col span="24" class="chart-item">
        <div class="chart-title">导游语言</div>
        <div class="chart-wrapper" id="40">
          <canvas
            id="language"
            v-if="type == '2'"
            style="width: 90vw; height: auto"
          ></canvas>
        </div>
      </van-col>
    </van-row>
    <van-row v-if="type == '3'" class="chart-container">
      <van-col span="24" class="total-bar">
        <van-row>
          <van-col span="8" class="bar-title">行程总数</van-col>
          <van-col span="16" class="bar-value">{{ plans_total_count }}</van-col>
        </van-row>
      </van-col>
      <van-col span="24" class="chart-item">
        <div class="chart-title">行程节点</div>
        <div class="chart-wrapper">
          <canvas
            id="node_count"
            v-if="type == '3'"
            style="width: 90vw; height: auto"
          ></canvas>
        </div>
      </van-col>
      <van-col span="24" class="chart-item">
        <div class="chart-title">使用次数</div>
        <div class="chart-wrapper">
          <canvas
            id="used_count"
            v-if="type == '3'"
            style="width: 90vw; height: auto"
          ></canvas>
        </div>
      </van-col>
    </van-row>
    <van-row v-if="type == '4'" class="chart-container">
      <van-col span="24" class="total-bar">
        <van-row>
          <van-col span="8" class="bar-title">出团总次数</van-col>
          <van-col span="16" class="bar-value">{{ lists_total_count }}</van-col>
        </van-row>
      </van-col>
      <van-col span="24" class="chart-item">
        <div class="chart-title">出团次数</div>
        <van-row class="chart-wrapper" id="50">
          <van-col span="24">
            <canvas
              v-if="type == '4'"
              id="tourist_count"
              style="width: 90vw; height: 30vh"
            ></canvas>
          </van-col>
        </van-row>
        <!-- <div class="chart-wrapper">
          <canvas
            v-if="type == '4'"
            id="tourist_count"
            style="width: 90vw; height: auto"
          ></canvas>
        </div> -->
      </van-col>
      <van-col span="24" class="chart-item">
        <div class="chart-title">月统计</div>
        <van-row class="chart-wrapper" id="60">
          <van-col span="24">
            <canvas
              v-if="type == '4'"
              id="month_count"
              style="width: 90vw; height: 30vh"
            ></canvas>
          </van-col>
        </van-row>
      </van-col>
    </van-row>
  </div>
</template>
<script>
// import F2 from "@antv/f2";
import F2 from "@antv/f2/lib/index-all";
const Tooltip = require("@antv/f2/lib/plugin/tooltip");
F2.Chart.plugins.register(Tooltip);
export default {
  name: "dataCenter",
  data() {
    const _self = this;
    return {
      type: _self.$route.query.type ? _self.$route.query.type : "1",
      colors: [
        "#1890FF",
        "#13C2C2",
        "#2FC25B",
        "#FACC14",
        "#F04864",
        "#8543E0",
      ],
      companies_group_data: [],
      companies_total_count: "0",
      guides_total_count: "0",
      plans_total_count: "0",
      companies_pending_review_count: "0",
      guides_pending_review_count: "0",
      lists_total_count: "0",
      gender_data: [],
      tabs: [
        {
          id: 0,
          text: "旅行社数据",
          type: "1",
        },
        {
          id: 2,
          text: "导游数据",
          type: "2",
        },
        {
          id: 3,
          text: "行程数据",
          type: "3",
        },
        {
          id: 4,
          text: "出团数据",
          type: "4",
        },
      ],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async onTabClick(item) {
      if (item.type == "1") {
        this.type = item.type;
        await this.statistics_travel_companies();
      }
      if (item.type == "2") {
        this.type = item.type;
        await this.statistics_guides();
      }
      if (item.type == "3") {
        this.type = item.type;
        await this.statistics_travel_plans();
      }
      if (item.type == "4") {
        this.type = item.type;
        await this.statistics_travel_lists();
      }
    },
    async count_info() {
      const res = await this.$apis.count_info();
      this.companies_total_count = res.data.companies_total_count;
      this.guides_total_count = res.data.guides_total_count;
      this.plans_total_count = res.data.plans_total_count;
      this.companies_pending_review_count =
        res.data.companies_pending_review_count;
      this.guides_pending_review_count = res.data.guides_pending_review_count;
    },

    async statistics_guides() {
      const _self = this;
      const res = await this.$apis.statistics_guides();
      const gender = res.data.gender;
      // const gender_map = {};
      const gender_data = [];
      const gender_data_length = gender.length;
      var gender_total = 0;
      gender.forEach((item) => {
        gender_total = gender_total + item.guide_count;
      });
      gender.forEach((item) => {
        gender_data.push({
          name: item.guide_gender,
          percent: item.guide_count,
          a: "1",
        });
        this.$nextTick(() => {
          this.gender_data = gender_data;
          _self.renderCircle(
            gender_data,
            "gender",
            _self.colors.slice(0, gender_data_length)
          );
        });
      });
      const language = res.data.language;
      const language_data = [];
      const language_data_length = language.length;
      var language_total = 0;
      language.forEach((item) => {
        language_total = language_total + item.guide_count;
      });
      language.forEach((item) => {
        language_data.push({
          name: item.guide_language == null ? "其他" : item.guide_language,
          percent: item.guide_count,
          a: "1",
        });
        this.$nextTick(() => {
          _self.renderPie(
            language_data,
            "language",
            0.5,
            "name2",
            "p2",
            _self.colors.slice(0, language_data_length),
            "人"
          );
        });
      });
      const rate_star = res.data.rate_star;
      const rate_star_data = [];
      const rate_star_data_length = rate_star.length;
      var rate_star_total = 0;
      rate_star.forEach((item) => {
        rate_star_total = rate_star_total + item.guide_count;
      });
      rate_star.forEach((item) => {
        rate_star_data.push({
          name: item.guide_rate_star + "星导游",
          percent: item.guide_count,
          // a: "1",
        });
        this.$nextTick(() => {
          _self.renderBar(rate_star_data, "rate_star", ["#4492FF"], "人");
        });
      });
    },
    async statistics_travel_companies() {
      const _self = this;
      const res = await this.$apis.statistics_travel_companies();
      //评分
      const rate_star = res.data.rate_star;
      // const rate_star_map = {};
      const rate_star_data = [];
      const rate_star_data_length = rate_star.length;
      var rate_star_total = 0;
      rate_star.forEach((item) => {
        rate_star_total = rate_star_total + item.guide_count;
      });
      rate_star.forEach((item) => {
        // rate_star_map[item.guide_rate_star+''] = item.guide_count + "人";
        rate_star_data.push({
          name: item.company_rate_star + "星旅行社",
          percent: item.company_count,
        });
        this.$nextTick(() => {
          _self.renderBar(
            rate_star_data,
            "companies_rate_star",
            ["#4492FF"],
            "个"
            // _self.colors.slice(0, rate_star_data_length),
            // "个"
          );
        });
      });
      //规模
      const companies_group = res.data.guide;
      const companies_group_data = [];
      const companies_group_data_length = companies_group.length;
      var companies_group_total = 0;
      companies_group.forEach((item) => {
        companies_group_total = companies_group_total + item.company_count;
      });
      const colors = ["#55CAFC", "#13C2C2", "#40A9FF"];
      const bg_colors = [
        "#D4F1FF",
        "rgba(106,197,115,0.17)",
        "rgba(64,169,255,0.12)",
      ];
      const icons = ["ground-1", "ground-2", "ground-3"];
      companies_group.forEach((item, index) => {
        // rate_star_map[item.guide_rate_star+''] = item.guide_count + "人";
        var dname = "";
        if (item.company_guides_count == "group0-10") {
          dname = "导游数(0-10)";
        }
        if (item.company_guides_count == "group10-30") {
          dname = "导游数(10-30)";
        }
        if (item.company_guides_count == "group30+") {
          dname = "导游数(30+)";
        }
        companies_group_data.push({
          // name: item.company_guides_count,
          name: dname,
          percent: item.company_count,
          color: colors[index],
          icon: icons[index],
          bg_color: bg_colors[index],
        });
        this.$nextTick(() => {
          // _self.renderPie(
          //   companies_group_data,
          //   "companies_group",
          //   _self.colors.slice(0, companies_group_data_length),
          //   "个"
          // );
          this.companies_group_data = companies_group_data;
        });
      });
    },
    async statistics_travel_plans() {
      const _self = this;
      const res = await this.$apis.statistics_travel_plans();
      //行程节点
      const node_count = res.data.node_count;
      // const rate_star_map = {};
      const node_count_data = [];
      const node_count_data_length = node_count.length;
      var node_count_total = 0;
      node_count.forEach((item) => {
        node_count_total = node_count_total + item.plan_count;
      });
      node_count.forEach((item) => {
        var dname = "";
        if (item.travel_plan_nodes_count == "group0-6") {
          dname = "短程";
        }
        if (item.travel_plan_nodes_count == "group6-14") {
          dname = "中程";
        }
        if (item.travel_plan_nodes_count == "group14+") {
          dname = "远程";
        }
        node_count_data.push({
          // name: item.travel_plan_nodes_count,
          name: dname,
          percent: item.plan_count,
          a: "1",
        });
        this.$nextTick(() => {
          _self.renderBar(node_count_data, "node_count", ["#6AC573"], "个");
        });
      });
      //使用次数
      const used_count = res.data.used_count;
      // const rate_star_map = {};
      const used_count_data = [];
      const used_count_data_length = used_count.length;
      var used_count_total = 0;
      used_count.forEach((item) => {
        used_count_total = used_count_total + item.plan_count;
      });
      used_count.forEach((item, index) => {
        // rate_star_map[item.guide_rate_star+''] = item.guide_count + "人";
        used_count_data.push({
          // name: item.plan_name+'\n'+item.company_name,
          name: "Top " + (index + 1),
          percent: item.plan_used_count,
          company_name: item.company_name,
          plan_name: item.plan_name,
        });
        this.$nextTick(() => {
          _self.renderBar(used_count_data, "used_count", ["#6AC573"], "次", 1);
        });
      });
    },
    async statistics_travel_lists() {
      const _self = this;
      const res = await this.$apis.statistics_travel_lists();
      //按人数规模

      var lists_total_count = 0;
      const tourist_count_data = [];
      const tourist_count = res.data.tourist_count;
      tourist_count.forEach((item) => {
        lists_total_count = lists_total_count + item.list_count;
        tourist_count_data.push({
          name: "人数" + item.travel_list_tourists_count.slice(5),
          percent: item.list_count,
        });
      });
      this.lists_total_count = lists_total_count;

      this.$nextTick(() => {
        _self.renderBar(tourist_count_data, "tourist_count", ["#6AC573"], "次");
      });

      const month_count = res.data.month_count;
      const month_count_data = [];
      month_count.forEach((item) => {
        month_count_data.push({
          name: item.list_month,
          percent: item.list_count,
        });
      });
      this.$nextTick(() => {
        _self.renderLine(month_count_data, "month_count");
      });
    },
    renderLine(data, id, unit = "次") {
      const max_data_arr = [];
      data.forEach((item) => {
        max_data_arr.push(item.percent);
      });
      const max_data = Math.max(...max_data_arr);
      const chart = new F2.Chart({
        id: id,
        pixelRatio: window.devicePixelRatio,
      });

      const defs = {
        name: {
          type: "cat", // 声明 a 字段的类型
        },
        percent: {
          min: 0, // 手动指定最小值
          // max: 20, // 手动指定最大值
          tickCount: max_data > 10 ? 4 : 2,
        },
      };
      chart.source(data, defs);
      chart.axis("name", {
        label: function label(text, index, total) {
          const textCfg = {
            rotate: Math.PI / 3,
            textAlign: "start",
            textBaseline: "middle"
          };
          if (index === 0) {
            textCfg.textAlign = "left";
          } else if (index === total - 1) {
            // textCfg.textAlign = "right";
          }
          return textCfg;
        },
      });
      chart.tooltip(false);
      chart.tooltip({
        triggerOn: ["touchstart", "touchmove", "click"], // tooltip 出现的触发行为，可自定义，用法同 legend 的 triggerOn
        triggerOff: "touchend",
        alwaysShow: false,
        onShow: function onShow(ev) {
          var items = ev.items;

          items[0].value = items[0].value + unit;
          items[0].name = items[0].title;
        },
      });
      chart.line().position("name*percent").shape("smooth");
      chart.point().position("name*percent").shape("smooth").style({
        stroke: "#fff",
        lineWidth: 1,
      });
      chart.render();
    },
    renderCircle(data, id, colors) {
      const chart = new F2.Chart({
        id: id,
        pixelRatio: window.devicePixelRatio,
        padding: [5, "auto"],
      });
      chart.source(data, {
        percent: {
          formatter: function formatter(val) {
            return val + "人";
          },
        },
      });
      chart.tooltip(false);
      chart.tooltip({
        triggerOn: ["touchstart", "touchmove", "click"], // tooltip 出现的触发行为，可自定义，用法同 legend 的 triggerOn
        triggerOff: "touchend",
      });
      // chart.legend({
      //   position: 'left',
      //   itemFormatter: function itemFormatter(val) {
      //     return val
      //   }
      // });
      chart.legend(false);
      chart.coord("polar", {
        transposed: true,
        innerRadius: 0.7,
        radius: 0.85,
      });
      chart.axis(false);
      chart
        .interval()
        .position("a*percent")
        .color("name", colors)
        .adjust("stack");

      // chart.guide().html({
      //   position: [ '50%', '50%' ],
      //   html: `<div style="width: 250px;height: 40px;text-align: center;">
      //   <div style="width:50%;float:left">
      //    <div style="font-size: 10px"></div>
      //       <div style="font-size: 12px">133.08 亿</div>
      //   </div>
      //       <div style="width:50%;float:left">
      //    <div style="font-size: 10px"></div>
      //       <div style="font-size: 12px">133.08 亿</div>
      //   </div>
      //     </div>`
      // });
      chart.render();
    },
    renderPie(data, id, innerRadius, name, percent, colors, unit) {
      const chart = new F2.Chart({
        id: id,
        pixelRatio: window.devicePixelRatio,
        plugins: Tooltip,
      });
      chart.source(data, {
        percent: {
          formatter: function formatter(val) {
            return val + unit;
          },
        },
      });
      chart.tooltip(false);
      chart.tooltip({
        triggerOn: ["touchstart", "touchmove", "click"], // tooltip 出现的触发行为，可自定义，用法同 legend 的 triggerOn
        triggerOff: "touchend",
      });
      // 添加饼图文本
      chart.coord("polar", {
        transposed: true,
        radius: 0.9,
        innerRadius: innerRadius,
      });
      chart.legend(false);
      chart.axis(false);
      chart.pieLabel({
        sidePadding: 30,
        activeShape: true,
        inflectionOffset:5,
        lineHeight:30,
        adjustOffset:10,
        anchorOffset:0,
        label1: function label1(data, color) {
          return {
            text: data.name,
            fill: color,
          };
        },
        label2: function label2(data) {
          return {
            text: data.percent + unit,
            fill: "#808080",
            fontWeight: "bold",
          };
        },
        // onClick: function onClick(ev) {
        //   const data = ev.data;
        //   if (data) {
        //     document.getElementById(name).innerHTML= data.name;
        //     document.getElementById(percent).innerHTML = data.percent

        //   }
        // }
      });
      chart.guide().html({
        position: ["50%", "50%"],
        html: `<div style="text-align: center;width:150px;height: 50px;">\n      <p style="font-size: 12px;color: #999;margin: 0" id="${name}"></p>\n      <p style="font-size: 18px;color: #343434;margin: 0;font-weight: bold;" id="${percent}"></p>\n      </div>`,
      });

      chart
        .interval()
        .position("a*percent")
        .color("name", colors)
        .adjust("stack")
        .style({
          lineWidth: 1,
          stroke: "#fff",
          lineJoin: "round",
          lineCap: "round",
        })
        .animate({
          appear: {
            duration: 1200,
            easing: "bounceOut",
          },
        });

      chart.render();
    },
    renderBar(data, id, colors, unit, type = 0) {
      const max_data_arr = [];
      data.forEach((item) => {
        max_data_arr.push(item.percent);
      });
      const max_data = Math.max(...max_data_arr);
      const chart = new F2.Chart({
        id: id,
        pixelRatio: window.devicePixelRatio,
        plugins: Tooltip,
      });
      let  max = 0
      if(Number(max_data)<=5){
        max = 6
      }else if(max_data>5&&max_data<=8){
        max = 8
      }else if(Number(max_data)>8){
        max = Number(max_data)
      }
      const defs = {
        name: {
          type: "cat", // 声明 a 字段的类型
        },
        percent: {
          min: 0, // 手动指定最小值
          max: max, // 手动指定最大值
          tickCount: max_data>10?5:4,
        },
      };
      chart.source(data, defs);
      chart.legend(false);
      chart.tooltip(false);
      chart.tooltip({
        triggerOn: ["touchstart", "touchmove", "click", "touch"], // tooltip 出现的触发行为，可自定义，用法同 legend 的 triggerOn
        triggerOff: "touchend",
        alwaysShow: false,
        onShow: function onShow(ev) {

          var items = ev.items;
          if (type == 1) {
            items[0].name =
              items[0].origin.plan_name +
              "(" +
              items[0].origin.company_name +
              ")";
          }
          items[0].value = items[0].value + unit;
        },
      });

      chart
        .interval()
        .position("name*percent")
        .style({
          radius: [5, 5, 5, 5],
        })
        .size(10)
        .color("name", colors);
      chart.render();
    },
  },
  created() {
    this.count_info();
  },
  mounted() {
    setTimeout(() => {
      if (this.type == 1) {
        this.statistics_travel_companies();
      }
      if (this.type == 2) {
        this.statistics_guides();
      }
      if (this.type == 3) {
        this.statistics_travel_plans();
      }
      if (this.type == 4) {
        this.statistics_travel_lists();
      }
    }, 100);
  },
  components: {},
};
</script>
<style scoped>
#dataCenter {
  height: 100vh;
  overflow-y: scroll;
  /* padding-top:22vh; */
}
.header {
  height: 20vh;
  line-height: 10vh;
  padding: 0 0.3rem;
  background: #6c94f6;
  border-bottom-left-radius: 1rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.tab-item {
  opacity: 0.47;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.tab-item-active {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.chart-container {
  width: 96%;
  margin: 0 auto;
  position: fixed;
  top:22vh;
  left:2vw;
  /* background: #fff; */
  /* margin-top: 1vh; */
  padding: 0.2rem;
  border-radius: 0.2rem;
  height: 80vh;
  overflow-y: scroll;
  padding-top: 0rem;
  padding-bottom: 3rem;
}
.chart-item {
  /* margin-bottom: 20px; */
}
.total-bar {
  height: 1rem;
  line-height: 1rem;
  background: #fff;
  border-radius: 0.3rem;
  padding: 0 0.5rem;
}
.bar-title {
  text-align: center;
  font-size: 0.25rem;
  color: #333;
  font-weight: bold;
}
.bar-value {
  text-align: right;
  font-weight: bold;
  font-size: 0.5rem;
}
.chart-title {
  height: 0.8rem;
  line-height: 0.8rem;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
}
.chart-wrapper {
  background: #fff;
  border-radius: 0.3rem;
  overflow-x: hidden;
}
.progress-bar {
  height: 1.2rem;
  background-color: #fff;
  line-height: 1.2rem;
  border-radius: 0.3rem;
  padding: 0 0.2rem;
  position: relative;
  margin-bottom: 10px;
}
.ground_icon {
  font-size: 0.8rem;
  position: absolute;
  left: 0.4rem;
  top: 0.25rem;
}
.bar-top {
  height: 0.6rem;
  line-height: 0.6rem;
}
.bar-text {
  font-size: 16px;
  color: #333333;
  margin-right: 10px;
  font-weight: bold;
}
.bar-number {
  font-size: 16px;
  color: #55cafc;
  margin-right: 10px;
  font-weight: bold;
}
.bar-unit {
  font-size: 10px;

  color: rgba(9, 48, 102, 0.48);
}
.bar-bottom {
  height: 0.6rem;
  line-height: 0.6rem;
  position: relative;
}
.bar {
  height: 0.3rem;
  width: 100%;
  border-radius: 6px;
  background: #d4f1ff;
  position: absolute;
  top: 0.15rem;
  left: 0;
}
.bar-progress {
  height: 0.3rem;
  width: 10%;
  border-radius: 6px;
  background: #55cafc;
  position: absolute;
  top: 0.15rem;
  left: 0;
}
.circle-1 {
  display: inline-block;
  height: 0.3rem;
  width: 0.3rem;
  border-radius: 50%;
  background: #2e8eff;
}
.circle-text {
  font-size: 0.3rem;
  color: #333;
  font-weight: bold;
  margin-left: 10px;
}
.number-container {
  background: #ffffff;
  height: 0.5rem;
  padding: 0.1rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 13px;
  text-align: center;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  line-height: 0.3rem;
  float: right;
  width: 1.2rem;
}
.unit {
  color: rgba(9, 48, 102, 0.48);
  font-size: 10px;
  margin-left: 5px;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
</style>